import * as React from 'react';
import { useSelector } from 'react-redux';
import Truncate from 'react-truncate';
import CustomLink from '../../_common/custom-link';
import SignInButton from '../../_common/sign-in-button';
import { getText, get } from '../../../helpers';
import { ReactComponent as UserIcon } from '../../../assets/svg/icons/user.svg';
import Dropdown from '../../_common/dropdown-button';

const myAccountText = getText('generic.myAccount');

function GlobalHeaderProfileMenu(props: GlobalHeaderProfileMenuProps) {
    const { className } = props;
    const userInfo = useSelector((state: RootStoreState) => get(state, 'user.userInfo', null));
    const userName = userInfo ? `${userInfo.FirstName} ${userInfo.LastName}` : '';

    return (
        <Dropdown
            className={className}
            listContent={[
                <CustomLink key="my-account" text={myAccountText} url="/my-account" title={myAccountText} isFullWidth />,
                <SignInButton key="sign-in" isFullWidth />
            ]}
            buttonContent={
                <>
                    <UserIcon className="global-header-profile-menu__icon" />
                    <span className="global-header-profile-menu__username" data-hj-suppress data-dd-privacy="mask"><Truncate trimWhitespace width={100}>{userName}</Truncate></span>
                </>
            }
            buttonClass="global-header-profile-menu__link"
        />
    );
}

export default React.memo(GlobalHeaderProfileMenu);
